var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0 ma-0", attrs: { fluid: "" } },
    [
      _c(
        "v-card",
        {
          staticClass: "pb-0 mb-0 pt-0",
          attrs: { flat: "", height: "1000px" },
        },
        [
          _c("div"),
          _c(
            "v-col",
            [
              _c(
                "v-sheet",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-4",
                          attrs: { outlined: "", color: "grey darken-2" },
                          on: { click: _vm.setToday },
                        },
                        [_vm._v(" Today ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            fab: "",
                            text: "",
                            small: "",
                            color: "grey darken-2",
                          },
                          on: { click: _vm.prev },
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v(" mdi-chevron-left "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            fab: "",
                            text: "",
                            small: "",
                            color: "grey darken-2",
                          },
                          on: { click: _vm.next },
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v(" mdi-chevron-right "),
                          ]),
                        ],
                        1
                      ),
                      _vm.$refs.calendar
                        ? _c("v-toolbar-title", [
                            _vm._v(
                              " " + _vm._s(_vm.$refs.calendar.title) + " "
                            ),
                          ])
                        : _c("v-toolbar-title", [
                            _vm._v(" " + _vm._s(this.cal_title) + " "),
                          ]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { height: "40", color: "success" },
                          on: {
                            click: function ($event) {
                              ;(_vm.component = "AddEvent"), (_vm.modal = true)
                            },
                          },
                        },
                        [_vm._v(" Add Billing Period ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-sheet",
            { attrs: { height: "825px" } },
            [
              _c("v-calendar", {
                ref: "calendar",
                attrs: {
                  color: "primary",
                  type: "month",
                  events: _vm.events,
                  "event-start": "start_date",
                  "event-end": "end_date",
                  "event-name": "period_name",
                },
                on: {
                  "click:date": function ($event) {
                    ;(_vm.component = "AddEvent"), (_vm.modal = true)
                  },
                  change: _vm.updateRange,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "event",
                      fn: function (ref) {
                        var event = ref.event
                        return [
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  ;(_vm.component = "EventModal"),
                                    (_vm.modal = true),
                                    (_vm.selectedEvent = event)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(event.period_name) + " ")]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.focus,
                  callback: function ($$v) {
                    _vm.focus = $$v
                  },
                  expression: "focus",
                },
              }),
            ],
            1
          ),
          _c(_vm.component, {
            key: _vm.componentKey,
            tag: "Component",
            attrs: { value: _vm.modal, selectedEvent: this.selectedEvent },
            on: {
              "update:value": function ($event) {
                _vm.modal = $event
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }